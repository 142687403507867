import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./carousel.scss";
import { prodData } from "../../data";
import Cards from "../cards/Cards";
const CarouselBanner = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1300 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1300, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className='car-banner'>
        <h3>Best Shopping experience</h3>
      <Carousel responsive={responsive} > 
        {prodData.map((item) => 
         <div className="car-ban">
          <Cards item={item} key={item.id}/>
         </div>
        )}
       
      </Carousel>
      
    </div>
  );
};

export default CarouselBanner;
