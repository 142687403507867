
import { IoIosArrowForward } from "react-icons/io";
import {LuRepeat} from 'react-icons/lu'
import {Link} from 'react-router-dom'
import "./cartpage.scss";
import CartItem from "./CartItem";
import Footer from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { resetCart } from "../../Redux/shopSlice";
import { ToastContainer, toast } from "react-toastify";
import { themeContext } from "../../App";
import PayModal from "../../components/PayModal/PayModal";
const CartPage = () => {
  const cartItem = useSelector((state) => state.shopItems.productsData)
  const {modal, toggleModal} = useContext(themeContext)
  const [total,setTotal] = useState(0.00)
  const dispatch = useDispatch()
  console.log('====================================');
  console.log(modal);
  console.log('====================================');
  useEffect(() =>{
    let price = 0
    cartItem.map((item) => {
      price += item.price * item.qauntity
      return price
    })
    setTotal(price.toFixed(2))
  },[cartItem])


  

  return (
    <>
   
    <div className='cart-page'>
   
      <div className='cart-product'>
        <div className='header'>
          <div>
            <h3>My Cart</h3>
          </div>
          <div className="buttons" >
          <button onClick={ ()=>dispatch(resetCart()) & toast.error(`CART HAS BEEN CLEARED`)} className="shop2"  >
              Clear Cart
              <span>
                <LuRepeat />
              </span>
            </button>
            <Link to='/products'>
            <button  className='shop'>
              Countinue Shopping
              <span>
                <IoIosArrowForward />
              </span>
            </button>
            </Link>
          
          </div>
        </div>
        {total === "0.00" ? <div className="empty-cart">
        <h1>Your Cart is empty</h1>
          </div> : <CartItem/>   }
       
      </div>
      <div className='checkout'>
     
          <div className="subtotal">
            <h2>SubTotal:</h2>
            <p>${total}</p>
          </div>
          
          <div className="tax">
            <h2>Tax:</h2>
            <p>$200</p>
          </div>
          <div className="total">
            <h2>Total</h2>
            <p>${total}</p>
          </div>
          <div className="shipping">
            <h2>Shipping: <h5>$0.00</h5></h2>
            <p>(Free shipment)</p>

          </div>
          <div className="buy">
          <button onClick={() => toggleModal() }>
        CheckOut <span><IoIosArrowForward/></span>
      </button>
          </div>
          
      </div>
       <ToastContainer
        position='top-left'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
    </div>
    <PayModal open={modal}/>
    <Footer/>
    </>
  );
};

export default CartPage;
