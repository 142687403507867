
import {AiFillStar} from 'react-icons/ai'
import './singlepage.scss'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addToCart } from '../../Redux/shopSlice'
import Footer from '../../components/Footer/Footer'
import CarouselBanner from '../../components/carousel/Carousel'
import Reviews from '../../components/reviews/Reviews'


const SingleProduct = () => {
 const location = useLocation()
 const [data,setData] = useState([])
 const dispatch = useDispatch()
 useEffect(() => {
  setData(location.state.item)
 }, [])
 console.log('====================================');
 console.log(data);
 console.log('====================================');


  return (
    <>
    <div className='single-product'>
        <div className="image-wrapper">
          <img src={data.image} alt="" />
        </div>
        <div className="details-wrapper">
          <h6>{data.category}</h6>
          <h3>{data.title}</h3>
          <div className="prices">
            <p>${(data.price * 1.64).toFixed(2)}</p>
            <h6>${data.price}</h6>
          </div>
          <div className="rating">
            <div className="stars">
              <span>
              <AiFillStar/>
              <AiFillStar/>
              <AiFillStar/>
              <AiFillStar/>
              <AiFillStar/>
              </span>
              <p>(1 customer review)</p>
              </div>
              </div>
              <div className="desc">
                <p>{data.description}</p>
              </div>
              <div className="add-btn">
                <div className="counters">   
                  <span >-</span>
                  <p>{data.quantity ? data.quantity : '1'}</p>
                  <span>+</span>
                </div>
                <button
                  onClick={() => dispatch(addToCart({
                    id:data.id,
                    image:data.image,
                    title:data.title,
                    description:data.description,
                    quantity:1,
                    price:data.price
                  }))}
                >Add To Cart</button>
              </div>
          
        </div>

    </div>
    <Reviews/>
    <CarouselBanner/>
    <Footer/>
    </>
  )
}

export default SingleProduct