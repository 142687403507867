
import { createContext, useState } from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import SginUp from './components/SginUp/SginUp';

import Products from './pages/Products/Products';
import CartPage from './pages/cart/CartPage';
import Home from './pages/home/Home';
import SingleProduct from './pages/singlepage/SingleProduct';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import NewNav from './components/NewNav/NewNav.jsx'
export const themeContext = createContext(null)


function App() {
  const [theme,setTheme] = useState("dark")
  const [modal,setModal] = useState(false)
  const toggleModal = () =>{
    setModal((modal) => !modal)
  }
  const toggleTheme = () =>{
    setTheme((theme) => theme === "light" ? "dark" : "light")
  }
  return (
    <themeContext.Provider value={{theme, toggleTheme, toggleModal,modal} }>

<BrowserRouter>
      <div className="App" id={theme}>
        <NewNav/>
       
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/products' element={<Products/>}/>
          <Route path='/sginup' element={<SginUp/>}/>
          <Route path='/singleproduct/:id' element={<SingleProduct/>}/>
          <Route path='/cartpage' element={ <CartPage/>}/>
        </Routes>
       
      </div>
      
    </BrowserRouter>
    
    </themeContext.Provider>
    
    

  );
}

export default App;
