import React from "react";
import "./footer.scss";
import { CgShapeRhombus } from "react-icons/cg";
import payment from '../../img/payment.png'
const Footer = () => {
  return (
    <>
      <footer className='footer'>
        <div className='footer-links'>
          <div className='logo'>
            <h2>AXIOS</h2>
          </div>
          <ul className='footer-link'>
          <li>
              <span>
                <CgShapeRhombus />
              </span>{" "}
              Home
            </li>
            <li>
              <span>
                <CgShapeRhombus />
              </span>{" "}
              Shop
            </li>
            <li>
              <span>
                <CgShapeRhombus />
              </span>{" "}
              About
            </li>
            <li>
              <span>
                <CgShapeRhombus />
              </span>{" "}
              Contact
            </li>
          </ul>
        </div>
        <div className='footer-links'>
          <ul className='footer-link'>
            <div className='logo2'>
              <h2>Quick Links</h2>
            </div>
            <li>
              <span>
                <CgShapeRhombus />
              </span>{" "}
              Home
            </li>
            <li>
              <span>
                <CgShapeRhombus />
              </span>{" "}
              Shop
            </li>
            <li>
              <span>
                <CgShapeRhombus />
              </span>{" "}
              About
            </li>
            <li>
              <span>
                <CgShapeRhombus />
              </span>{" "}
              Contact
            </li>
          </ul>
        </div>
        <div className='footer-news'>
          <h4>Join Us Now!</h4>
          <input type='email' placeholder='Please Enter Your E-mail' />
          <button>Join Now</button>
          <img src={payment} alt="" />
        </div>
      </footer>
      <div className='copyrights'>
        <p>Copy Rights ©2023 Tohamy-Dev</p>
      </div>
    </>
  );
};

export default Footer;
