import React from 'react'
import {AiFillStar} from 'react-icons/ai'
import {ImShare} from 'react-icons/im'
import {AiFillLike, AiFillDislike} from 'react-icons/ai'
import {BsStarHalf} from 'react-icons/bs'
import './reviews.scss'
const Reviews = () => {
  return (
    <>
    <div className="review-header">
        <h2>The Reviews Are In</h2>
        <button><ImShare/> WRITE A REVIEW</button>
    </div>
     <div className='reviews'>
        <div className="review-card">
            <div className="date">
                <p>yahia mohammed</p>
                <p>19/02/2020</p>
            </div>
            <div className="stars">
            <span>
              <AiFillStar/>
              <AiFillStar/>
              <AiFillStar/>
              <AiFillStar/>
              <AiFillStar/>
              </span>
            </div>
            <div className="comments">
                <h5><strong>Usage:</strong> All of the above</h5>
                <h5><strong>purchasing:</strong> I saw you on shark tank!</h5>
            </div>
            <div className="main-review">
                <h2>You Are The Best!</h2>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Reiciendis voluptas atque voluptatibus eius tenetur sint excepturi aliquid ipsam hic sequi animi quo libero, ut rem accusamus quaerat iure reprehenderit? Eligendi?</p>

            </div>
            <div className="review-footer">
                <p><ImShare/> Share |</p>
                <p>was this review helpful? <AiFillLike/> <AiFillDislike/></p>
            </div>
        </div>
        <div className="review-card">
            <div className="date">
                <p>Yasser Arafat</p>
                <p>22/08/1947</p>
            </div>
            <div className="stars">
            <span>
              <AiFillStar/>
              <AiFillStar/>
              <AiFillStar/>
              <BsStarHalf/>
              </span>
            </div>
            <div className="comments">
                <h5><strong>Usage:</strong> Quality of service</h5>
                <h5><strong>purchasing:</strong> Reached you by a friedn recommendation</h5>
            </div>
            <div className="main-review">
                <h2>High quality products</h2>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Reiciendis voluptas atque voluptatibus eius tenetur sint excepturi aliquid ipsam hic sequi animi quo libero, ut rem accusamus quaerat iure reprehenderit? Eligendi?</p>

            </div>
            <div className="review-footer">
                <p><ImShare/> Share |</p>
                <p>was this review helpful? <AiFillLike/> <AiFillDislike/></p>
            </div>
        </div>
        <div className="review-card">
            <div className="date">
                <p>Michael Jakson</p>
                <p>09/11/2001</p>
            </div>
            <div className="stars">
            <span>
              <AiFillStar/>
              <AiFillStar/>
              <AiFillStar/>
              <AiFillStar/>
              <AiFillStar/>
              </span>
            </div>
            <div className="comments">
                <h5><strong>Usage:</strong> Great Musicison</h5>
                <h5><strong>purchasing:</strong> Baby are you ok?</h5>
            </div>
            <div className="main-review">
                <h2>Smooth Criminal</h2>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Reiciendis voluptas atque voluptatibus eius tenetur sint excepturi aliquid ipsam hic sequi animi quo libero, ut rem accusamus quaerat iure reprehenderit? Eligendi?</p>

            </div>
            <div className="review-footer">
                <p><ImShare/> Share |</p>
                <p>was this review helpful? <AiFillLike/> <AiFillDislike/></p>
            </div>
        </div>
        <div className="review-card">
            <div className="date">
                <p>yahia mohammed</p>
                <p>19/02/2020</p>
            </div>
            <div className="stars">
            <span>
              <AiFillStar/>
              <AiFillStar/>
              <AiFillStar/>
              <AiFillStar/>
              <AiFillStar/>
              </span>
            </div>
            <div className="comments">
                <h5><strong>Usage:</strong> All of the above</h5>
                <h5><strong>purchasing:</strong> I saw you on shark tank!</h5>
            </div>
            <div className="main-review">
                <h2>You Are The Best!</h2>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Reiciendis voluptas atque voluptatibus eius tenetur sint excepturi aliquid ipsam hic sequi animi quo libero, ut rem accusamus quaerat iure reprehenderit? Eligendi?</p>

            </div>
            <div className="review-footer">
                <p><ImShare/> Share |</p>
                <p>was this review helpful? <AiFillLike/> <AiFillDislike/></p>
            </div>
        </div>
    </div>
    </>
   
  )
}

export default Reviews