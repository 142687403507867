import { useEffect, useState } from 'react'
import Footer from '../../components/Footer/Footer'
import Preloader from '../../components/Prealoader/Preloader'
import Banner from '../../components/banner/Banner'
import CarouselBanner from '../../components/carousel/Carousel'
import CoolHover from '../../components/coolHover/CoolHover'
import './home.scss'


const Home = () => {  
  const [loading , setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1500);
  },[])
 
  return (
    <>
      {loading ?
    <Preloader/>
    :
    <div className='home'>
    <Banner/>
    <CarouselBanner/>
    <CoolHover/>
    <Footer/>
</div>
    }
   
    </>
  
  )
}

export default Home