// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth,  GoogleAuthProvider,FacebookAuthProvider} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA0kLH8UkxOqd4UqznkJXcSwBp1eBoPVMQ",
  authDomain: "axios-cb262.firebaseapp.com",
  projectId: "axios-cb262",
  storageBucket: "axios-cb262.appspot.com",
  messagingSenderId: "53316860531",
  appId: "1:53316860531:web:a96caa80538f16f8460806"
};

// Initialize Firebase
 const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
const fbProvider = new FacebookAuthProvider();
 const auth = getAuth(app)
 const db = getFirestore(app)
 export {app,auth ,provider,db, fbProvider}