import React, { useEffect, useState } from "react";
import { onAuthStateChanged, signOut  } from "firebase/auth";
import { auth } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import { RiAccountCircleLine } from "react-icons/ri";
import { BiSolidDownArrow } from "react-icons/bi";
import "./navbar.scss";
const Authin = () => {
  const [userAuth, setUserAuth] = useState("");
  const navigate = useNavigate();



  useEffect(() => {
    const listnAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserAuth(user);

      } else {
        setUserAuth(null);
      }
    });
    return listnAuth();
  }, []);

  const handleClick = () => {
    signOut(auth)
      .then(() => {
        setTimeout(() => {
          navigate("/");
          window.location.reload();
          console.log("user is out");
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {userAuth === null ? (
        <>
        <div >
        <Link to='/sginup' className="logout">
            <RiAccountCircleLine />
          </Link>
        </div>
        
        </>
      ) : (
        <>
          <div className='profile'>
            <img
              src={userAuth.photoURL}
              alt=''
                   />
            <p style={{ textAlign: "center" }}>
              Me <BiSolidDownArrow />
            </p>
            <div className='options'>
              <span>{userAuth.displayName}</span>
              <span>Settings</span>
              <span onClick={handleClick}>Logout</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Authin;
