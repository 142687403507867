import babies from './img/babies.jpg'
export const prodData= [
    {
        id:1,
        title:"Cool amazing shirt",
        description:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat incidunt ipsum dicta?',
        oldpirce:124,
        price:89,
        img:'https://cdn.pixabay.com/photo/2014/08/26/21/49/shirts-428627_1280.jpg'
    },
    {
        id:2,
        title:"Best For the Ladies",
        description:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat incidunt ipsum dicta?',
        oldpirce:24,
        price:45,
        img:'https://cdn.pixabay.com/photo/2017/08/01/11/48/woman-2564660_1280.jpg'
    },
    {
        id:3,
        title:"Amazing fit for the Dudes",
        description:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat incidunt ipsum dicta?',
        oldpirce:104,
        price:104,
        img:'https://cdn.pixabay.com/photo/2016/11/29/01/34/man-1866572_1280.jpg'
    },
    {
        id:4,
        title:"Top Quality Shoeses",
        description:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat incidunt ipsum dicta?',
        oldpirce:124,
        price:200,
        img:'https://cdn.pixabay.com/photo/2017/04/05/01/12/traveler-2203666_1280.jpg'
    },
    {
        id:5,
        title:"Some Clothes and Stuff",
        description:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat incidunt ipsum dicta?',
        oldpirce:124,
        price:89,
        img:'https://cdn.pixabay.com/photo/2013/11/14/12/34/neckties-210347_1280.jpg'
    },
    {
        id:6,
        title:"For the cute Babies",
        description:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat incidunt ipsum dicta?',
        oldpirce:124,
        price:89,
        img:'https://cdn.pixabay.com/photo/2017/02/08/02/56/booties-2047596_1280.jpg'
    },
    {
        id:1,
        title:"Cool amazing shirt",
        description:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat incidunt ipsum dicta?',
        oldpirce:124,
        price:89,
        img:'https://cdn.pixabay.com/photo/2014/08/26/21/49/shirts-428627_1280.jpg'
    },
    {
        id:2,
        title:"Best For the Ladies",
        description:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat incidunt ipsum dicta?',
        oldpirce:24,
        price:45,
        img:'https://cdn.pixabay.com/photo/2017/08/01/11/48/woman-2564660_1280.jpg'
    },
    {
        id:3,
        title:"Amazing fit for the Dudes",
        description:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat incidunt ipsum dicta?',
        oldpirce:104,
        price:104,
        img:'https://cdn.pixabay.com/photo/2017/11/02/14/26/man-2911327_1280.jpg'
    },
    {
        id:4,
        title:"Top Quality Shoeses",
        description:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat incidunt ipsum dicta?',
        oldpirce:124,
        price:200,
        img:'https://cdn.pixabay.com/photo/2017/04/05/01/12/traveler-2203666_1280.jpg'
    },
    {
        id:5,
        title:"Some Clothes and Stuff",
        description:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat incidunt ipsum dicta?',
        oldpirce:124,
        price:89,
        img:'https://cdn.pixabay.com/photo/2013/11/14/12/34/neckties-210347_1280.jpg'
    },
    {
        id:6,
        title:"For the cute Babies",
        description:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat incidunt ipsum dicta?',
        oldpirce:124,
        price:89,
        img:'https://cdn.pixabay.com/photo/2017/02/08/02/56/booties-2047596_1280.jpg'
    },
    
]
