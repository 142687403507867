
import './produtcs.scss'
import Product from '../Product/Product'

import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { fetchData } from '../../Redux/productSlice'
import Footer from '../../components/Footer/Footer'
import Preloader from '../../components/Prealoader/Preloader'



const Products = () => {
const [loading,setLoading] = useState(false)
const product = useSelector((state) => state.product)
const dispatch = useDispatch()
useEffect(() => {
 setLoading(true) 
dispatch(fetchData())
setTimeout(() => {
  setLoading(false)
}, 2000);

},[])


  return (
    <>
   
    {loading ? 
   <Preloader/>
 
  :
  <>
   <div className='products'>
        <h2>Your Journey Starts Here</h2>
        <div className="categories">
            <ul className='category'>
                <li>Men</li>
                <li>Women</li>
                <li>Kids</li>
                <li>Accsesories</li>
                <li>Shoes</li>
            </ul>
        </div>
        <div className="collection">
          {product.map((item) =>    <Product key={item.id} item={item}/> )}
    
        
      
      
        </div>
       
    </div>
    <Footer/>
  </>
  }
   
    
    </>
    
  )
}

export default Products