import React from 'react'
import {AiOutlineArrowRight} from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import {  addToCart } from '../../Redux/shopSlice';
import {Link, useNavigate} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';




const Product = ({item}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const toSinglePage = () =>{
    navigate(`/singleproduct/${item._id}`,{
      state:{
        item:item
      }
    })
  }





  return (
    <>
    <div>
          <div className="products-wrap">
            <div className="product-card" >
                <div className="product-img" >
                  
                  <img src={item.image} alt="" onClick={toSinglePage} />
                 
                    
                </div>
                <div className="product-details">
                    <h3>{item.title}</h3>
                    <p>{(item.description).slice(0,20)}</p>

                </div>
                <div className="add-btn">
                <h6>${item.price}</h6>
                <button
                onClick={() => dispatch(addToCart({
                  _id:item._id,
                  title: item.title,
                  image: item.image,
                  price: item.price,
                  qauntity: 1,
                  description: item.description,
                }))& toast.success(`${item.title} Added to cart`)
              }
              
                >Add To Cart <span> <AiOutlineArrowRight/> </span></button>
                </div>
                
            </div>
          
        </div>
        <ToastContainer
        position='top-left'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
    </div>

    </>
    
  )
}

export default Product