import React from "react";
import { BsTrashFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { decreaseCart, increamentCart, removeFromCart } from "../../Redux/shopSlice";
import Footer from "../../components/Footer/Footer";
import { ToastContainer, toast } from "react-toastify";

const CartItem = () => {
  const cartItem = useSelector((state) => state.shopItems.productsData)
  const dispatch = useDispatch()
 

  return (
    <>
    {cartItem.map((item) =>
     <div key={item._id} >
     <div className='items'>
       <img src={item.image} alt='' />
       <div className='item-title'>
         <h4>{item.title}</h4>
       </div>
       <div className='add-btn'>
         <div className='counters'>
           <span
           onClick={() => dispatch(decreaseCart({
            _id: item._id,
            title: item.title,
            image: item.image,
            price: item.price,
            qauntity: 1,
            description: item.description,
        }))}
           >
             -
           </span>
           <p>{item.qauntity}</p>
           <span
           onClick={() => dispatch(increamentCart({
            _id: item._id,
            title: item.title,
            image: item.image,
            price: item.price,
            qauntity: 1,
            description: item.description,
        }))}
            >
             +
           </span>
         </div>
       </div>
       <div className='price'>
         <h4>${(item.qauntity * item.price).toFixed(2)}</h4>
       </div>
       <div className='trash'>
         <span
         onClick={() => dispatch(removeFromCart(item._id)) & toast.error(`${item.title} is removed`)}
         >
           <BsTrashFill />
         </span>
       </div>
     </div>
   </div>

  )}
     

     <ToastContainer
        position='top-left'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
    </>
  );
};

export default CartItem;
