import React, { useEffect, useState } from "react";
import "./sginup.scss";
import googled from "../../img/google.png";
import facebook from "../../img/Facebook-logo.png";
import sginup from "../../img/contact.png";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, signInWithPopup,updateProfile  } from "firebase/auth";
import { auth, provider,fbProvider } from "../../firebase";
const SginUp = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [username, setUserName] = useState("");
  const [google, setGoogle] = useState("");





  const createUser = (e) => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, email, pass, username)
      .then((userCredential) => {
      userCredential.user.displayName = username
      if(auth.currentUser === null){
        return
      }else{
        updateProfile(auth.currentUser,{
          displayName : username
        
        })
        
      }

      setTimeout(() => {
          navigate("/");
          window.location.reload()
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const sginGoogle = () => {
    signInWithPopup(auth, provider).then((data) => {
      setGoogle(data.user.email);
    
      setTimeout(() => {
        navigate("/");
        window.location.reload();
      }, 1000);
    });
  };
  const sginFaceBook = () =>{
    signInWithPopup(auth, fbProvider).then((res) =>{
      console.log(res);
    }).catch(err => console.log(err))
  }
 
  return (
    <>
      <div className='sginup'>
        <h2>Become one of us!</h2>
        <p>Sgin Up Now and Get free Shipping plus 30$ Cash Back !!!</p>
        <div className='sgin-container'>
          <div className='left-sgin'>
            <img src={sginup} alt='' />
          </div>
          <div className='right-sgin'>
            <div>
              <h2>Sgin Up Here</h2>
            </div>
            <form onSubmit={createUser}>
              <input
                type='text'
                placeholder='Enter your Username...'
                className='input'
                onChange={(e) => setUserName(e.target.value)}
              />
              <input type='text' placeholder='Enter Last Name' />
              <input
                type='email'
                placeholder='E-mail'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type='password'
                placeholder='Password'
                value={pass}
                onChange={(e) => setPass(e.target.value)}
              />
              <input type='number' placeholder='Phone Number' />
            </form>
            <button onClick={createUser} className='sginup-btn'>
              Sgin Up
            </button>
            <div className='soical-login'>
              <h2>Or Sgin up with Social Media</h2>
              <button onClick={sginGoogle}>
                <img src={googled} alt='' />
                <h3>Google</h3>
              </button>
              <button onClick={sginFaceBook}>
                <img src={facebook} alt='' />
                <h3>Facebook</h3>
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SginUp;
