import React, { useContext } from "react";
import "./paymodal.css";
import { themeContext } from "../../App";
const PayModal = ({ open }) => {
    const {toggleModal} = useContext(themeContext)
  if (!open) return null;
  return (
    <>
      <div className='payment'>
        <div className='pay-title'>
          <h1>Payment Details</h1>
        </div>
        <form className='payment-form'>
          <div className='name'>
            <label>Name On Card</label>
            <input type='text' placeholder='Name on card' />
          </div>
          <div className='name'>
            <label>Card Number</label>
            <input type='number' placeholder='NO.' />
          </div>
          <div className='card-date'>
            <label>Expiry Date</label>
            <select>
              <option>January</option>
              <option>February</option>
              <option>March</option>
              <option>April</option>
              <option>May</option>
              <option>June</option>
              <option>July</option>
              <option>August</option>
              <option>September</option>
              <option>October</option>
              <option>November</option>
              <option>December</option>
            </select>
            <select>
              <option>2018</option>
              <option>2019</option>
              <option>2020</option>
              <option>2021</option>
              <option>2022</option>
              <option>2023</option>
            </select>
            <label>CVV</label>
            <input maxlength='3' placeholder='633' type='number'/>
          </div>
        </form>
        <div className="buttons">
            <button className="green">
                Proceed with payment
            </button>
            <button onClick={() =>toggleModal()} className="red">
                Cancel
            </button>
        </div>
      </div>
    </>
  );
};

export default PayModal;
