import React from 'react'
import './banner.scss'
import banner from '../../img/banner.jpg'
const Banner = () => {
  return (
    <div>
        <div className="banner-wrapper">
            <div className="text-wrapper">
            <h3>Find all one you need in one store & order <br /> it from your couch</h3>
            <div className="search">
            <input type="search" placeholder='Enter' />
            <button>Search</button>
            </div>
           
            </div>
         
        </div>
    </div>
  )
}

export default Banner