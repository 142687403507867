import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";



export const fetchData = createAsyncThunk("productSlice/fetchData", async() =>{
    const res = await fetch('https://fakestoreapiserver.reactbd.com/products');
    const data = res.json()
    return data
})

export const productSlice = createSlice({
    initialState:[],
    name:'productSlice',
    reducers:{},
    extraReducers:(builder) =>{
        builder.addCase(fetchData.fulfilled,(state,action) =>{
            return action.payload
        })
    }
})

export const {} = productSlice.actions
export default productSlice.reducer