import React from 'react'
import './cards.scss'
const Cards = ({item}) => {
   
  return (
    <div className='cards'>
        <div className="img-wrap">
            <img src={item.img} alt="" />
        </div>
        <div className="card-text">
            <h5>{item.title}</h5>
            <p>{item.description}</p>
            <button>Shop Now</button>
        </div>
        
        
    </div>
  )
}

export default Cards