import { Link } from "react-router-dom";
import { useState } from "react";
import { BsFillCartFill } from "react-icons/bs";
import { CgShapeRhombus } from "react-icons/cg";
import "./newnav.scss";
import Authin from "../Navbar/Authin";
import { useSelector } from "react-redux";
import DarkMode from "../DarkMode/DarkMode";
import { FaBars } from "react-icons/fa";
const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [navBar, setNavBar] = useState(false);
  const cartItem = useSelector((state) => state.shopItems.productsData);
  window.onscroll = () => {
    setIsScrolled(window.pageYOffset === 0 ? false : true);
    return () => (window.onscroll = null);
  };
  return (
    <>
      <div className={isScrolled ? "navbar scrolled" : "navbar"}>
        <div className='container'>
          <div className='left'>
            <div className='logo'>
          
              <p>AXIOS</p>
           
              
            </div>
          </div>
          <div className='middle'>
            <span>
              <Link to='/'>Home</Link>
            </span>
            <span>
              <Link to='/products'>Products</Link>
            </span>
            <span>
              <Link to='/patients'>About</Link>
            </span>
            <span>
              <Link>Contact</Link>
            </span>
          </div>
          <div className='right'>
            <Authin />
            <div className='carts'>
              <Link to='/cartpage' className='cart'>
                <BsFillCartFill />
                <span className='cart-number'>{cartItem.length}</span>
              </Link>
            </div>
            <div className='darkmode'>
              <DarkMode />
            </div>
          </div>
        </div>
      </div>
      <div className={isScrolled ? " mob-nav scrolled" : "mob-nav"}>
        <div className='left'>
        <div className="darkmsode">
                <DarkMode />
                </div>
          <div className='bars' onClick={()=> setNavBar((prev) => !prev)}>
            <FaBars />
            <div className={navBar ? 'links-open' :"links"}>
               <div className='logo'>
              <p>AXIOS</p>
            </div>
          
              <span>
                <Link to='/'>Home</Link>
              </span>
              <span>
                <Link to='/products'> Products</Link>
              </span>
              <span>
                <Link to='/patients'>About</Link>
              </span>
              <span>
                <Link>Contact</Link>
              </span>
              <div className="mob-footer">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maiores, deleniti.
              </div>
              
            </div>
            
                
            
          </div>
        </div>
        <div className='middle'>
          <div className='logo'>
            <p>AXIOS</p>
          </div>
        </div>
        <div className='right'>
          <Authin />
          <div className='carts'>
            <Link to='/cartpage' className='cart'>
              <BsFillCartFill />
              <span className='cart-number'>{cartItem.length}</span>
            </Link>
          </div>
          <div className='darkmode'></div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
