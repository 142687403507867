import React from 'react'
import {BallTriangle} from 'react-loader-spinner'
import './preloader.scss'
const Preloader = () => {
  return (
    <div>
          <div className="preload"> 
       <BallTriangle
     height={300}
     width={300}
     radius={5}
     color="lightgreen"
     ariaLabel="ball-triangle-loading"
     wrapperClass={{}}
     wrapperStyle=""
     visible={true}
   /></div>
    </div>
  )
}

export default Preloader