import React from "react";
import "./coolhover.css";
import backg from '../../img/background.png'
const CoolHover = () => {
  return (
    <div className='cool'>
      <div className='right'>
        <div className="img-wrapper">
        <img src={backg} alt="" />
            <div className="img-text">
                <h5>Lorem, ipsum dolor sit amet  consectetur adipisicing elit. Alias illo suscipit aspernatur officiis voluptates placeat incidunt voluptatum. Repudiandae quidem ab perspiciatis reprehenderit dicta accusamus corrupti.</h5>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ex, cum!</p>
            </div>
        </div>
   
      </div>
      <div className='left'>
        <div className='card-swiper'>
          <div className='card-groups'>
            <div className='card-group' data-index='0' data-status='active'>
              <div className='little-card card'></div>
              <div className='big-card card'></div>
              <div className='little-card card'></div>
              <div className='big-card card'></div>
              <div className='little-card card'></div>
              <div className='big-card card'></div>
              <div className='little-card card'></div>
              <div className='big-card card'></div>
            </div>
            <div className='card-group' data-index='1' data-status='unknown'>
              <div className='little-card card'></div>
              <div className='big-card card'></div>
              <div className='little-card card'></div>
              <div className='big-card card'></div>
              <div className='little-card card'></div>
              <div className='big-card card'></div>
              <div className='little-card card'></div>
              <div className='big-card card'></div>
            </div>
            <div className='card-group' data-index='2' data-status='unknown'>
              <div className='little-card card'></div>
              <div className='big-card card'></div>
              <div className='little-card card'></div>
              <div className='big-card card'></div>
              <div className='little-card card'></div>
              <div className='big-card card'></div>
              <div className='little-card card'></div>
              <div className='big-card card'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoolHover;
