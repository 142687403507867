import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    productsData : []
    
}

export const shopSlice = createSlice({
    initialState,
    name: 'shopSlice',
    reducers:{
      
        addToCart:(state,action)=>{
            const item = state.productsData.find((item) => item._id === action.payload._id)
            if(item){
                item.quantity += action.payload.quantity
            }else{
                state.productsData.push(action.payload)
            }
        },
        decreaseCart: (state,action) =>{
            const item = state.productsData.find(
                (item) => item._id === action.payload._id
            )
            if(item.qauntity === 1){
                item.qauntity =1;
            }else{
                item.qauntity--;
            }
        },
        increamentCart: (state,action) =>{
            const item = state.productsData.find(
                (item) => item._id === action.payload._id
            )
            if(item.qauntity){
                item.qauntity ++;
            }
        },
        removeFromCart:(state,action) =>{
            state.productsData = state.productsData.filter((item) => item._id !== action.payload)
        },
        resetCart:(state,action) =>{
            state.productsData =[];
        }
    }

})

export const {addToCart, increamentCart, decreaseCart, removeFromCart,resetCart} = shopSlice.actions
export default shopSlice.reducer